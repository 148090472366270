/* global axios */

export default class StorageService {
  constructor() {
    this.axios = axios;
  }

  getStorageCompanyServiceItems(serviceId, folderId) {
    return this.axios.get(`service/${serviceId}/storage/company/folder/${folderId}`);
  }

  storeFolder(service, folderName, folderId = null) {
    return this.axios.post(`service/storage/company/folder/store`, {
      company_id: service.company_content_id,
      service_id: service.id,
      folder_id: folderId,
      name: folderName
    });
  }

  storeFile(formData) {
    return this.axios.post(`service/storage/company/files/store`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  downloadFile(fileId) {
    return this.axios.post(`service/storage/company/file/download/${fileId}`);
  }

  destroyFolder(folderId) {
    return this.axios.post(`service/storage/company/folder/destroy/${folderId}`);
  }

  destroyFile(fileId) {
    return this.axios.post(`service/storage/company/file/destroy/${fileId}`);
  }

  generatePromomemo(id) {
    return this.axios.post(`service/${id}/promomemo/generate`);
  }

  getFilteredFolderAndFiles(id, filter) {
    let url = `service/${id}/storage/folder-files`;
    url = filter ? `${url}/${filter}` : url;

    return this.axios.get(url);
  }
}
