<template>
  <div v-show="show" id="storage">
    <header-service
      :service="service"
      currentTab="Documentos"
      :isSaving="!isLoading && isSaving"
    >
      <div slot="actions">
        <div
          class="m-btn-group m-btn-group--pill btn-group"
          role="group"
          aria-label="First group"
        >
          <button
            type="button"
            @click="getService"
            class="m-btn btn btn-info"
            title="Ir para documentos"
          >
            <i class="la la-arrow-left"></i>
          </button>
          <button
            v-if="$authorization.can('document-store-folder')"
            title="Adicionar Pasta"
            type="button"
            class="m-btn btn btn-success"
            data-toggle="modal"
            data-target="#store-folder"
          >
            <i class="la la-folder"></i>
          </button>

          <a
            title="Baixar Arquivos"
            target="__blank"
            :href="`${baseUrl}services/${service.id}/files/download/all`"
            type="button"
            class="m-btn btn btn-danger"
          >
            <i class="la la-download"></i>
          </a>

          <button
            title="Gerar Promomemo"
            type="button"
            :class="
              `m-btn btn btn-warning ${
                isGeneratingPromomemo
                  ? 'm-loader m-loader--light m-loader--right'
                  : ''
              } `
            "
            class="m-btn btn btn-warning"
            @click="generatePromomemo"
          >
            <i class="la la-file-word-o"></i>
          </button>

          <button
            v-if="$authorization.can('document-store-file')"
            @click="openFile"
            title="Adicionar Arquivo"
            type="button"
            class="m-btn btn btn-primary"
          >
            <i class="la la-file"></i>
          </button>
        </div>
        <input
          type="file"
          id="store-file"
          multiple
          @change="storeFile($event.target)"
          style="display:none"
        />
      </div>
    </header-service>
    <div class="row mt-5">
      <div class="col-lg-3 col-md-4">
        <menu-service :service="service"></menu-service>
      </div>
      <div class="col-lg-9 col-md-8">
        <div class="m-content">
          <div class="row">
            <div class="col-12">
              <div class="m-portlet m-portlet--full-height">
                <div class="m-portlet__body">
                  <div class="row">
                    <div class="col-12 pr-0">
                      <div class="col-4 pull-right text-right">
                        <input
                          type="text"
                          v-model="filter"
                          @keyup.enter="search"
                          placeholder="Pesquisar..."
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <u-table :columns="columns">
                    <tr
                      v-if="storageCompanyServices.length"
                      v-for="(folder, indexFolder) in storageCompanyServices"
                      :key="`folder-${indexFolder}`"
                    >
                      <td class="pt-4 pb-4">
                        <i class="fa fa-folder mr-2"></i>
                        {{ folder.name }}
                      </td>
                      <td class="pt-4 pb-4">
                        {{ $helper.convertDatePtBr(folder.created_at) }}
                      </td>
                      <td class="pt-4 pb-4 text-right">
                        <a
                          v-if="
                            !folder.protected &&
                              $authorization.can('document-destroy-folder')
                          "
                          class="mr-3"
                          href="#destroy-folder"
                          @click="setFolderId(folder.id)"
                          data-toggle="modal"
                          title="Excluir pasta"
                        >
                          <i class="la la-trash-o"></i>
                        </a>
                        <a
                          href="javascript:;"
                          @click="getStorageCompanyService(folder)"
                          title="Visualizar"
                        >
                          <i class="la la-search-plus search-icon"></i>
                        </a>
                      </td>
                    </tr>
                    <tr
                      v-if="storageCompanyServicesFiles.length"
                      v-for="(file, indexFile) in storageCompanyServicesFiles"
                      :key="`file-${indexFile}`"
                    >
                      <td class="pt-4 pb-4">
                        <i class="la la-file mr-2"></i>
                        {{ file.original_name }}
                      </td>
                      <td class="pt-4 pb-4">
                        {{ $helper.convertDatePtBr(file.created_at) }}
                      </td>
                      <td class="pt-4 pb-4 text-right">
                        <a
                          v-if="$authorization.can('document-destroy-file')"
                          href="#destroy-file"
                          class="mr-3"
                          @click="setFileId(file.id)"
                          data-toggle="modal"
                          title="Excluir arquivo"
                        >
                          <i class="la la-trash-o"></i>
                        </a>
                        <a
                          v-if="$authorization.can('document-download-file')"
                          :href="`${file.download}`"
                          target="_blank"
                          title="Download"
                        >
                          <i class="la la-cloud-download download-icon"></i>
                        </a>
                      </td>
                    </tr>
                  </u-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Store Folder -->
        <u-modal id="store-folder" title="Adicionar Pasta">
          <div slot="body">
            <div class="form-group">
              <label class="form-control-label">Nome</label>
              <input
                type="text"
                class="form-control"
                @keyup.enter="storeFolder"
                v-model="folderName"
              />
            </div>
          </div>
          <div slot="footer">
            <button
              type="button"
              class="btn btn-secondary mr-2"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="storeFolder"
            >
              Salvar
            </button>
          </div>
        </u-modal>
        <!-- Destroy Folder -->
        <u-modal id="destroy-folder" title="Excluir Pasta">
          <div slot="body">
            <div class="form-group">
              <label class="form-control-label"
                >Deseja realmente excluir esta pasta?</label
              >
            </div>
          </div>
          <div slot="footer">
            <button
              type="button"
              class="btn btn-secondary mr-2"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="destroyFolder"
            >
              Excluir
            </button>
          </div>
        </u-modal>
        <!-- Destroy File -->
        <u-modal id="destroy-file" title="Excluir Arquivo">
          <div slot="body">
            <div class="form-group">
              <label class="form-control-label"
                >Deseja realmente excluir este arquivo?</label
              >
            </div>
          </div>
          <div slot="footer">
            <button
              type="button"
              class="btn btn-secondary mr-2"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              data-dismiss="modal"
              @click="destroyFile"
            >
              Excluir
            </button>
          </div>
        </u-modal>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderService from "../components/HeaderService";
import MenuService from "../components/MenuService";
import UTable from "@/components/UTable";
import UModal from "@/components/UModal";
import ServiceHelper from "../support";
import ServiceService from "../services/ServiceService";
import StorageService from "../services/StorageService";

export default {
  name: "Storage",
  data() {
    return {
      isLoading: true,
      isSaving: false,
      baseUrl: process.env.VUE_APP_SGVAS_API,
      service: {},
      storageCompanyServiceId: "",
      storageCompanyServices: {},
      storageCompanyServicesFiles: [],
      folderId: "",
      folder: "",
      fileId: "",
      folderName: "",
      uploadPercent: 0,
      showUploadPercent: false,
      goBack: false,
      isGeneratingPromomemo: false,
      columns: ["Nome", "Criado em", ""],
      show: false,
      filter: ""
    };
  },
  components: {
    HeaderService,
    MenuService,
    UTable,
    UModal
  },
  created() {
    this.serviceService = new ServiceService();
    this.storageService = new StorageService();
    this.getService();
  },
  methods: {
    getService() {
      this.serviceService.getService(this.$route.params.id).then(response => {
        this.service = response.data;
        this.usageChannels = response.data.usageChannels;
        this.usageOperationSystems = response.data.usageOperationSystems;
        this.storageCompanyServices = response.data.storageCompanyService;
        this.storageCompanyServicesFiles =
          response.data.storageCompanyServiceFiles;
        this.storageCompanyServiceId = this.storageCompanyServices[0].id;
        this.goBack = false;
        this.show = true;
      });
    },
    search() {
      this.storageService
        .getFilteredFolderAndFiles(this.$route.params.id, this.filter)
        .then(response => {
          this.storageCompanyServices = response.data.storageCompanyService;
          this.storageCompanyServicesFiles =
            response.data.storageCompanyServicesFiles;
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    generatePromomemo() {
      this.isGeneratingPromomemo = true;
      this.storageService
        .generatePromomemo(this.service.id)
        .then(response => {
          this.isGeneratingPromomemo = false;
          swal("Sucesso!", "Promomemo criado", "success");
          this.goPromomemoFolder();

          if (
            this.storageCompanyServicesFiles &&
            this.storageCompanyServicesFiles.length
          ) {
            this.storageCompanyServicesFiles.unshift(response.data.promomemo);
          } else {
            this.storageCompanyServicesFiles.push(response.data.promomemo);
          }
        })
        .catch(error => {
          console.log(error);
          this.isGeneratingPromomemo = false;
          swal("Ops!", "Ocorreu um erro ao criar o promomemo", "error");
        });
    },
    goPromomemoFolder() {
      if (this.storageCompanyServices && this.storageCompanyServices.length) {
        this.storageCompanyServices.forEach(folder => {
          if (!folder.folder_id && folder.name === "Promomemo") {
            this.getStorageCompanyService(folder);
          }
        });
      }
    },
    getStorageCompanyService(folder) {
      this.storageService
        .getStorageCompanyServiceItems(this.service.id, folder.id)
        .then(response => {
          this.storageCompanyServices = response.data.storageCompanyService;
          this.storageCompanyServicesFiles =
            response.data.storageCompanyServiceFiles;
          this.folderId = folder.id;
          this.goBack = true;
        });
    },
    storeFolder() {
      $("#store-folder").modal("hide");
      if (!this.folderName) {
        swal(
          "Ops!",
          "Dados Inválidos. Verifique os dados informados e tente novamente.",
          "error"
        );
      } else {
        this.storageService
          .storeFolder(this.service, this.folderName, this.folderId)
          .then(response => {
            this.storageCompanyServices.push(response.data);
          });
        this.folderName = "";
      }
    },
    destroyFolder() {
      this.storageService
        .destroyFolder(this.folderId)
        .then(response => {
          this.storageCompanyServices.forEach((item, index) => {
            if (this.folderId === item.id) {
              this.storageCompanyServices.splice(index, 1);
            }
          });
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    setFolderId(folderId) {
      this.folderId = folderId;
    },
    storeFile(element) {
      if (element.files.length) {
        this.uploadPercent = 0;
        this.showUploadPercent = true;
        const formData = new FormData();
        formData.append("service_name", this.service.name);
        formData.append(
          "storage_company_service_id",
          this.storageCompanyServiceId
        );
        formData.append("folder_id", this.folderId);
        for (let file in element.files) {
          formData.append("files[]", element.files[file]);
        }
        element.value = null;
        axios
          .post(`service/storage/company/files/store`, formData, {
            onUploadProgress: uploadEvent => {
              this.uploadPercent = Math.round(
                (uploadEvent.loaded / uploadEvent.total) * 100
              );
            },
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            response.data.forEach((file, index) => {
              this.storageCompanyServicesFiles.push(file);
            });
            this.showUploadPercent = false;
          })
          .catch(error => {
            this.showUploadPercent = false;
            console.log(error.response);
          });
      }
    },
    destroyFile() {
      this.storageService
        .destroyFile(this.fileId)
        .then(response => {
          this.storageCompanyServicesFiles.forEach((item, index) => {
            if (this.fileId === item.id) {
              this.storageCompanyServicesFiles.splice(index, 1);
            }
          });
        })
        .catch(error => {
          console.log(error.response);
        });
    },
    openFile() {
      document.getElementById("store-file").click();
    },
    setFileId(fileId) {
      this.fileId = fileId;
    }
  }
};
</script>

<style scoped>
.search-icon {
  color: #36a3f7 !important;
}
.download-icon {
  color: #34bfa3 !important;
}
</style>
